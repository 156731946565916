<template>
  <div class="w-full h-20">
    <h1 class="text-3xl text-center bg-blue-100 py-4">LOL 英雄对线分析</h1>
    <div class="heros grid grid-cols-1  place-items-center">
      <div v-for="hero in hero_list" :key="hero.heroId">
        <h2>{{hero.name}}</h2>
        <img  :src="'https://game.gtimg.cn/images/lol/act/img/champion/'+hero.alias+'.png'" alt="">
      </div>

    </div>
  </div>
</template>

<script>
import hero_list from "./assets/hero_list.json";

export default {
  data() {
    return {
      url: "",
      hero_list: hero_list.hero,
    };
  },
  methods: {},
};
</script>

<style>
</style>
